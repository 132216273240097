import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const Link = makeShortcode("Link");
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Suppose `}<inlineCode parentName="p">{`foo.internal`}</inlineCode>{` is running in AWS, and `}<inlineCode parentName="p">{`bar.internal`}</inlineCode>{` is running in GCP.
To communicate, requests must be sent across the public internet. Traditionally
this sort of cross-connectivity is secured using a network-level tunnel, like
an SSH tunnel or a VPN. These tunnels are often configured and managed ad-hoc.`}</p>
    <p>{`They can also be challenging to scale and operate and introduce a single point
of failure into an otherwise resilient system. With a PKI trust model and
automated certificate management, you can leverage mTLS for secure
cross-connectivity without a VPN to avoid these issues.`}</p>
    <p>{`In this scenario, you may want to run two CAs: one in AWS and one in GCP. That
way, you don’t need to expose open source `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to the public internet. To make this
work, these CAs must federate a PKI trust model, which means `}<inlineCode parentName="p">{`foo.internal`}</inlineCode>{` and
`}<inlineCode parentName="p">{`bar.internal`}</inlineCode>{` must trust both CAs to issue certificates.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/federation"
      }}><inlineCode parentName="a">{`step ca federation`}</inlineCode></a>{` subcommand can be used to distribute the bundle of
federated roots to workloads in both clouds. `}</p>
    <h2>{`About this tutorial`}</h2>
    <ul>
      <li parentName="ul">{`Learn how to secure comms across clouds using autonomous internal PKI.`}</li>
      <li parentName="ul">{`Examples include copy/paste code blocks and clonable code repos.`}</li>
      <li parentName="ul">{`When complete, you will have secure cross-connectivity without a VPN. `}</li>
      <li parentName="ul">{`Estimated effort: Reading time ~3 mins, Lab time ~10 to 60 mins. `}</li>
    </ul>
    <Alert severity="info" mdxType="Alert">
  <div>
    If you run into any issues please let us know <Link href="https://github.com/smallstep/certificates/discussions" mdxType="Link">in GitHub Discussions</Link>.
  </div>
    </Alert>
    <h2>{`Requirements`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Open Source -`}</strong>{` This tutorial assumes you have initialized and started up a `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{`instance using the steps in `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/getting-started"
        }}>{`Getting Started`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://smallstep.com/certificate-manager"
          }}>{`Smallstep Certificate Manager`}</a>{` -`}</strong>{` Please get in touch with `}<a parentName="li" {...{
          "href": "mailto:support@smallstep.com"
        }}>{`Smallstep Customer Success`}</a>{` if you want to federate an existing Root with a Certificate Manager Authority.`}</li>
    </ul>
    <h2>{`Overview`}</h2>
    <p>{`The basic federation tutorial below showcases how to securely facilitate communication between relying parties of multiple autonomous certificate authorities.`}</p>
    <p>{`This tutorial uses a pre-generated PKI. Do not use these pre-generated PKIs for `}<strong parentName="p">{`any`}</strong>{` purposes outside of this tutorial.`}</p>
    <p>{`You can find all of the code in this tutorial here:`}<br />{`
`}<a parentName="p" {...{
        "href": "https://github.com/smallstep/certificates/tree/master/examples/basic-federation"
      }}>{`https://github.com/smallstep/certificates/tree/master/examples/basic-federation`}</a></p>
    <p>{`This is what we will cover in this tutorial:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#launching-the-federated-online-cas"
        }}>{`Launching the federated online CAs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#bringing-up-the-demo-server"
        }}>{`Bringing up a demo server`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#running-the-demo-client"
        }}>{`Running the demo client`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#curl-as-a-client"
        }}>{`Curl as a client`}</a></li>
    </ul>
    <h2>{`Launching the federated online CAs`}</h2>
    <p>{`If you want to follow along with the example code, clone the repository and go to the `}<inlineCode parentName="p">{`basic-federation`}</inlineCode>{` folder.`}</p>
    <CodeBlock language="shell-session" copyText="git clone https://github.com/smallstep/certificates.git && cd ./certificates/examples/basic-federation" mdxType="CodeBlock">
      {`$ git clone https://github.com/smallstep/certificates.git
Cloning into 'certificates'...
remote: Enumerating objects: 16, done.
remote: Counting objects: 100% (16/16), done.
remote: Compressing objects: 100% (15/15), done.
remote: Total 8355 (delta 3), reused 5 (delta 0), pack-reused 8339
Receiving objects: 100% (8355/8355), 34.92 MiB | 11.05 MiB/s, done.
Resolving deltas: 100% (5727/5727), done.
$ cd ./certificates/examples/basic-federation`}</CodeBlock>
    <p>{`Open two terminals to use as online CAs and call them `}<inlineCode parentName="p">{`Cloud CA`}</inlineCode>{` and `}<inlineCode parentName="p">{`Kubernetes CA`}</inlineCode>{`. The password for both of these online CAs is `}<inlineCode parentName="p">{`password`}</inlineCode>{`.`}</p>
    <CodeBlock language="shell-session" copyText="step-ca ./pki/cloud/config/ca.federated.json" mdxType="CodeBlock">
      {`$ step-ca ./pki/cloud/config/ca.federated.json
Please enter the password to decrypt intermediate_ca_key: password
2019/01/22 13:38:52 Serving HTTPS on :1443 ...`}</CodeBlock>
    <CodeBlock language="shell-session" copyText="step-ca ./pki/kubernetes/config/ca.federated.json" mdxType="CodeBlock">
      {`$ step-ca ./pki/kubernetes/config/ca.federated.json
Please enter the password to decrypt intermediate_ca_key: password
2019/01/22 13:39:44 Serving HTTPS on :2443 ...`}</CodeBlock>
    <p>{`Notice the difference between the two configuration options below. `}<inlineCode parentName="p">{`Cloud CA`}</inlineCode>{` will list `}<inlineCode parentName="p">{`Kubernetes CA`}</inlineCode>{` in the `}<inlineCode parentName="p">{`federatedRoots`}</inlineCode>{` section and vice versa.`}</p>
    <CodeBlock language="shell-session" copyText="diff pki/cloud/config/ca.json pki/cloud/config/ca.federated.json" mdxType="CodeBlock">
      {`$ diff pki/cloud/config/ca.json pki/cloud/config/ca.federated.json
3c3
<    "federatedRoots": [],
---
>    "federatedRoots": ["pki/cloud/certs/kubernetes_root_ca.crt"],
`}</CodeBlock>
    <h2>{`Bringing up the demo server`}</h2>
    <p>{`Once both online CAs are up and running, let's bring up the demo server, using
a certificate from the `}<inlineCode parentName="p">{`Cloud CA`}</inlineCode>{`. This demo server leverages `}<a parentName="p" {...{
        "href": "https://pkg.go.dev/github.com/smallstep/certificates/ca"
      }}>{`step's
SDK`}</a>{` to obtain X.509
certificates, automatically renew them, and fetch a trusted roots bundle. When
it starts, it will report what root certificates it will use to authenticate
client certificates.`}</p>
    <CodeBlock language="shell-session" copyText="go run server/main.go $(step ca token --ca-url https://localhost:1443 --root ./pki/cloud/certs/root_ca.crt 127.0.0.1)" mdxType="CodeBlock">
      {`$ go run server/main.go $(step ca token --ca-url https://localhost:1443 --root ./pki/cloud/certs/root_ca.crt 127.0.0.1)
✔ Key ID: EE1ZiqkMaxsUdpz8SCSkRBzwK9TWUoidQnMnJ8Eryn8 (sebastian@smallstep.com)
✔ Please enter the password to decrypt the provisioner key: password
Server is using federated root certificates
Accepting certs anchored in CN=Smallstep Public Cloud Root CA
Accepting certs anchored in CN=Smallstep Kubernetes Root CA
Listening on :8443 ...`}</CodeBlock>
    <h2>{`Running the demo client`}</h2>
    <p>{`Similarly, step's SDK provides a client option to mutually authenticate connections to servers. It automatically handles certificate bootstrapping, renewal, and fetches a bundle of trusted roots. The demo client will send HTTP requests to the demo server periodically or about every 5s. This client is going to use a certificate from the `}<inlineCode parentName="p">{`Kubernetes CA`}</inlineCode>{`.`}</p>
    <CodeBlock language="shell-session" copyText="go run client/main.go $(step ca token sdk_client --ca-url https://localhost:2443 --root ./pki/kubernetes/certs/root_ca.crt)" mdxType="CodeBlock">
      {`$ go run client/main.go $(step ca token sdk_client --ca-url https://localhost:2443 --root ./pki/kubernetes/certs/root_ca.crt)
✔ Key ID: S5gYgpeqcIAgc1Zr4myZXpgJ_Ao4ryS6F6wqg9o8RYo (sebastian@smallstep.com)
✔ Please enter the password to decrypt the provisioner key: password
Server responded: Hello sdk_client (cert issued by 'Smallstep Kubernetes Root CA') at 2019-01-23 00:51:38.576648 +0000 UTC`}</CodeBlock>
    <h2>{`Curl as a client`}</h2>
    <p>{`While the demo client provides a convenient way to periodically send requests to the demo server, curl in combination with a client certificate from `}<inlineCode parentName="p">{`Kubernetes CA`}</inlineCode>{` can hit the server instead:`}</p>
    <CodeBlock language="shell-session" copyText="step ca certificate kube_client kube_client.crt kube_client.key --ca-url https://localhost:2443 --root pki/kubernetes/certs/root_ca.crt" mdxType="CodeBlock">
      {`$ step ca certificate kube_client kube_client.crt kube_client.key --ca-url https://localhost:2443 --root pki/kubernetes/certs/root_ca.crt
✔ Key ID: S5gYgpeqcIAgc1Zr4myZXpgJ_Ao4ryS6F6wqg9o8RYo (sebastian@smallstep.com)
✔ Please enter the password to decrypt the provisioner key:
✔ CA: https://localhost:2443/1.0/sign
✔ Certificate: kube_client.crt
✔ Private Key: kube_client.key`}</CodeBlock>
    <p>{`Federation relies on a bundle of multiple trusted roots which need to be fetched before being passed into curl.`}</p>
    <CodeBlock language="shell-session" copyText="step ca federation --ca-url https://localhost:1443 --root pki/cloud/certs/root_ca.crt federated.pem" mdxType="CodeBlock">
      {`$ step ca federation --ca-url https://localhost:1443 --root pki/cloud/certs/root_ca.crt federated.pem
The federation certificate bundle has been saved in federated.pem.`}</CodeBlock>
    <p>{`Then you have to pass the certificate issued by Kubernetes CA into curl using the appropriate command line flags:`}</p>
    <CodeBlock language="shell-session" copyText="curl -i --cacert federated.pem --cert kube_client.crt --key kube_client.key https://127.0.0.1:8443" mdxType="CodeBlock">
      {`$ curl -i --cacert federated.pem --cert kube_client.crt --key kube_client.key https://127.0.0.1:8443
HTTP/1.1 200 OK
Date: Mon, 28 Jan 2019 15:24:54 GMT
Content-Type: text/plain; charset=utf-8
Content-Length: 105\n
Hello kube_client (cert issued by 'Smallstep Kubernetes Root CA') at 2019-01-28 15:24:54.864373 +0000 UTC`}</CodeBlock>
    <p>{`Since the demo server is enrolled with the federated `}<inlineCode parentName="p">{`Cloud CA`}</inlineCode>{` that trusts
X.509 certificates issued by the `}<inlineCode parentName="p">{`Kubernetes CA`}</inlineCode>{` through federation, the connection
is successfully established.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      